.contact-section {
    width: 100vw;
    height: fit-content;
    display: flex;
    background-color: #000;
    place-content: center;
    padding: 30px 0;
    margin-bottom: 1px;
    overflow: hidden;
}

.contact-container form {
    display: flex;
    justify-content: center;
}

.contact-container h1 {
    font-size: 2.3em;
    color: #000;
}

.contact-container {
    width: fit-content;
    background-color: #FFC200;
    border-radius: 15px;
    opacity: .9;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    padding: 30px 0;
    box-shadow: 0px 0px 25px rgb(255, 255, 255);
}

.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 10px;
    font-weight: 600;
}



.lastName {
    border-radius: 0 20px 20px 0;  
}

.lastContainer {
    text-align: center;
}

form input, form textarea {
    border-radius: 20px;
    padding: 8px;
    width: 100%;
    border: #000 1px solid;
    background-color: rgb(164, 164, 164);
    font-weight: 600;
}


form label {
    color: black;
}

#submitButton {
   width: 9em;
   height: 3em;
   border: 3px solid black;
   border-radius: 20px;
   background-color: transparent;
   font-weight: 600;
   font-size: 1.1em;
   color:#000000;
   transition: all 200ms ease-in-out;
}

#submitButton:hover {
    font-size: 1.2em;
}
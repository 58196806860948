.skill-bx {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    background: linear-gradient(to bottom right, #001531 , #2e5b93);
}

.skill-bx h2 {
    font-size: 2.5em;
    align-self: center;
}

.skill-bx p {
    text-align: center;
    opacity: .7;
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
    justify-content: center;
}

.item h5 {
    padding-top: 15px;
    font-size: 1.4em;
}

.item img {
    width: 16vw;
}

@media (max-width: 500px){
    .item img {
        width: 25vw;
    }
}

@media (max-width: 392px){
    .item img {
        width: 40vw;
    }
}
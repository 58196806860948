:root{
    --primary: #003DFF;
    --secondary: #FFC200;
}


.banner {
    background-color: #23153c;
    height: 100vh;
}

.banner .avatar {
    min-width: 200px;
}

.banner .container {
    display:flex;
    position: relative;
    justify-content: space-between;
    top: 30vh;
    margin: 0 18%;
}

.container .header-img img {
     animation: updown 5s linear infinite;
     overflow: hidden;
     border-radius: 100%;
     width: 400px;
     height: 400px;
     object-fit: cover;
     object-position: center;
     border: 2px solid var(--secondary);
  }

  .hero {
    display: flex;
    flex-direction: column;
}

.hero h1{
    height: fit-content;
}

.hero p {
    max-width: 500px;
    font-size: 1.2em;
    margin: 20px 0;
    line-height: 2em;
}

.hero .button {
    color:'#C7A338';
}

@keyframes updown {
    0% {
        transform: translateY(-10px);
    }

    50% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(-10px);
    }
}

@media (max-width: 712px) {
    .container .header-img img{
       display: none;
    }

    .banner .container {
        top: 25vh;
    }

    .hero {
        width: 100%;
    }

    .hero p {
        line-height: 1.4em;
    }

    .banner {
        align-items: center;
    }
  }

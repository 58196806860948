.footer {
    height: 60px;
    background-color: black;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.logo {
    width: 50px;
    height: 50px;
}

.logo img {
    width: 50px;
    height: 50px;
}

.footer::after, .footer::before{
    content:'';
    background-color: #385CC7;
    width: 30vw;
    height: 2px;
}
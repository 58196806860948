.project {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.project h1 {
    font-size: 2.5em;
}

.project span, .project p {
    opacity: .8;
}

.project .grid-list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    max-width: 100vw;
}

.title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}



.card {
    display: flex;
    flex-direction: column;
    padding: 10px;
    height: 500px;
    margin: 15px ;
    background-color: hsl(0, 0%, 25%);
    border-radius: 20px;
    border: 2px solid black;
    justify-content: space-between;
    box-shadow: 5px 7px 5px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 5px 7px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 5px 7px 5px 0px rgba(0,0,0,0.75);
    transition: all 0.3s ease-out;
}

.card .img-container img {
    width: 30vw;
    border-radius: 10px;
    object-fit: cover;
    flex-shrink: 2;
    border: 2px solid rgb(0, 0, 0);
}

.card:hover {
    background-color: hwb(0 30% 75%);
    scale: 1.005;
}

.projectDesc {
    word-wrap: break-word;
    margin-bottom: auto;
}

#chips {
    display: flex;
    opacity: 100;
    flex-wrap: wrap;
}

#chip {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 0px 5px 2px 5px;
    margin: 5px 5px 0 0;
    font-size: smaller;
    color: #000000;
    border: 1px solid #000000;
    font-weight: 600;
}
#chip:hover{
    box-shadow: black;
}

.blue{
    background-color: rgb(92, 141, 233);

}

.orange {
    background-color: rgb(220, 160, 48);
}

.green {
    background-color: rgb(99, 248, 99);
}

.yellow{
    background-color: rgb(212, 212, 51);
}

.red {
    background-color: rgb(236, 118, 118);
}

.purple {
    background-color: rgb(143, 118, 236);
}

.black{
    background-color: rgb(74, 228, 255);
}

#cardContent {
    width:30vw;
}

#cardContent > p {
    margin-top: 3px;
}
.goToBtn {
    background-color: #ffa500;
    border: 1px solid #000000;
    padding: 5px;
    border-radius: 20px;
    color: #000000;
    font-weight: 500;
    text-decoration: none;
    width: 90%;
    text-align: center;
}

.btnHolder {
    width: 100%;
    height: fit-content;
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

@media (max-width: 712px){
    .card .img-container img {
        width: 40vw;
    }
    #cardContent {
    width:80vw;
    }
    .card .img-container img {
        width: 80vw;
    }

}

@media (max-width: 1081px){
    .card {
        height: fit-content;
    }
}

:root{
    --primary: #003DFF;
    --secondary: #FFC200;
}

nav.navbar{
    padding: 18px 0;
    position:fixed;
    width: 100%;
    top: 0;
    z-index: 99999;
    transition: all .18s ease-in-out;

}

.navbar-logo-nav {
    display: flex;
    align-items: center;
}

.navbar-nav a{
    text-decoration:none;
}

.navbar .container{
    display: flex;
    justify-content: space-evenly;
    padding: 0 10px;
}

nav.navbar a.navbar-brand img{
    width: 50px;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
    font-weight: 400;
    color:#fff !important;
    letter-spacing: .08px;
    padding: 0 25px;
    font-size: 18px;
    opacity: 0.75;
    transition: all 140ms ;
}

nav.navbar .navbar-nav .nav-link.navbar-link:hover,
nav.navbar .navbar-nav .nav-link.navbar-link.active {
    opacity: 1;

}

span.navbar-text {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.social-icon {
    display: inline-block;
    margin-left: 14px;
}

.social-icon a {
    width: 42px;
    height: 42px;
    background: var(--secondary);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
}

.navbar-text .social-icon .hover {
    font-size: 40px;
    background-color:#416ae4;
}

.navbar-text button {
    font-weight: 700;
    color: var(--secondary);
    border: 2px solid var(--secondary);
    padding: 18px 34px;
    font-size: 18px;
    margin-left: 18px;
    position: relative;
    background-color: transparent;
    transition: 0.3s ease-in-out;
  }
  .navbar-text button span {
    z-index: 1;
  }
  .navbar-text button::before {
    content: "";
    width: 0%;
    height: 100%;
    position: absolute;
    background-color: var(--secondary);
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
  }
  .navbar-text button:hover {
    color: #0b0b0b;
  }
  .navbar-text button:hover::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
  }

  #resume {
    text-decoration: none;
    color: white;
    transition: all 140ms ;
  }

  #resume:hover {
    text-decoration: none;
    color: rgb(211, 210, 210);
  }

  @media (min-width:663px) {
    nav.navbar.scrolled {
        padding: 0px 0;
        background-color: #0b0b0b;
        opacity: .8;
        transition: all .3s ease-in-out;
    }
  }
  @media (max-width: 712px) {
    .navbar .container{
        flex-direction: column;
        align-items: center;
        margin-top: -10px;
    }
  }

  @media (max-width: 662px) {
    .navbar-collapse{
        flex-direction: column;
    }

    .navbar-logo-nav {
        flex-direction: column;
    }
    .contact {
        display: none;
    }
    nav.navbar{
        position: absolute;
        background: none;
    }

  }
